import { styled } from "styled-components";

export const Container = styled.div`
  position: absolute;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px #00000033;
  border: 2px solid #f7e297;
  background-color: #fff4cb;
  padding: 22px 17px 15px 70px;
  display: flex;
  flex-direction: column;
  background-image: url(/images/lamp2.svg);
  background-repeat: no-repeat;
  background-size: 38px auto;
  background-position: 20px 18px;
  z-index: 1001;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
`;

const ButtonResetStyle = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ConfirmButton = styled(ButtonResetStyle)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  padding: 10px 21px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 12px;
  border-radius: 6px;
  margin: 9px 0 0 auto;
  width: max-content;
`;

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
