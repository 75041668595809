import { styled } from "styled-components";
import { Tooltip } from "react-tooltip";

export const Container = styled.div`
  position: absolute;
  right: 60px;
  top: 11px;
  border-radius: 5px;
  background-color: #f95959;
  padding: 10px 30px 15px 16px;
  min-width: 197px;
`;

export const Hellip = styled.p`
  padding: 0 0 22px;
  line-height: 8px;
  color: #fff;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 38px;
  font-weight: 800;
`;

export const Credits = styled.p`
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  white-space: nowrap;
`;

export const TextHighlight = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 25px;
  font-weight: 900;
  line-height: 30px;
`;

export const TextHighlightSm = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 700;
`;

export const Description = styled.p`
  color: #fff;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  padding-left: 14px;
`;

export const Info = styled.p`
  width: max-content;
  margin-left: auto;
  color: #fff;
  font-size: 12px;
`;

export const IconContainer = styled.div`
  width: 12px;
  height: 12px;
  box-shadow: 0 0 0 1px transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffff;
  margin-left: auto;
  position: absolute;
  right: 24px;
  bottom: 10px;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  font-size: 8px;
  ${({ $disabled }) => ($disabled ? "pointer-events: none;" : "")}
`;

export const InfoTooltip = styled(Tooltip)`
  position: absolute;
  width: 100%;
  bottom: unset !important;
  left: unset !important;
  right: 0 !important;
  top: calc(100% - 5px) !important;
  padding: 7px 17px 2px 9px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 18px 40px 0px #00000033;
  opacity: 1;
  z-index: 99999;

  &.react-tooltip__place-bottom {
    .react-tooltip-arrow {
      width: 15px;
      height: 15px;
      top: -8px !important;
      left: unset !important;
      right: 21px !important;
      bottom: unset !important;
      border-left: 1px solid #dfdfdf;
      border-top: 1px solid #dfdfdf;
      border-top-left-radius: 6px;
      transform: rotate(45deg);
    }
  }
`;

export const CreditsAllocatedValue = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 26px;
  font-weight: 800;
  line-height: 34.84px;
  color: #000000;
`;

export const CreditsAllocatedText = styled.div`
  margin-top: -5px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.4px;
  color: #959595;
`;

export const CreditsUsedBar = styled.div`
  margin-top: 7px;
  height: 6.13px;
  width: 100%;
  border-radius: 19px;
  background: #d9d9d9;

  div {
    height: 100%;
    width: ${({ $value }) => `${$value}%;`};
    border-radius: 19px;
    background: #471168;
  }
`;

export const CreditsUsedText = styled.div`
  margin-top: 7px;
  margin-bottom: ${({ $withSubscription }) =>
    $withSubscription ? "28px" : "10px"};
  font-size: 9px;
  font-weight: 400;
  line-height: 10.8px;
  color: #959595;
`;

export const SubscriptionPlan = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 9px;
  font-weight: 700;
  line-height: 10.8px;
  color: #4640de;
`;

export const UpgradePlanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -17px 0 -9px;
  width: calc(100% + 17px + 9px);
  height: 53.13px;
  background: #f8f8f8;
  border-radius: 0 0 6px 6px;
`;

export const UpgradePlanBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 32px;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  color: #ffffff;
  background: #471168;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
  &:hover {
    background: #5d0493;
  }
`;
