import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ $height }) => ($height ? $height : "100%")};
  width: 100%;
  ${({ $minWidth }) => ($minWidth ? `min-width: ${$minWidth} ` : "")};
  ${({ $parentSize }) => ($parentSize ? "" : "position: absolute;")};
  ${({ $zIndex }) => ($zIndex ? `z-index: ${$zIndex}` : "")};
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  margin: ${({ $margin }) => ($margin ? $margin : "0")};
  top: 0;
  left: 0;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
`;

const LoadingText = styled.div`
  position: absolute;
  margin-top: 60px;
  font-size: 13px;
  ${({ $color }) => ($color ? `color: ${$color}` : "")};
`;

const Loader = ({
  parentSize,
  margin,
  bgColor,
  minWidth,
  padding,
  height,
  zIndex,
  text,
  textColor,
}) => (
  <Container
    $parentSize={parentSize}
    $margin={margin}
    $bgColor={bgColor}
    $minWidth={minWidth}
    $padding={padding}
    $height={height}
    $zIndex={zIndex}
  >
    <Spinner width="60" height="60" />
    {text && <LoadingText $color={textColor}>{text}</LoadingText>}
  </Container>
);

export default Loader;
